import React from "react"
import { graphql } from "gatsby"

import PageComponent from "components/PageComponent"

const Index = ({ data }) => {
  return <PageComponent page={data.contentfulSite.indexPage} />
}

export default Index

export const query = graphql`
  query IndexPageQuery {
    contentfulSite {
      indexPage {
        ...Page
      }
    }
  }
`
